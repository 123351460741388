<template>
  <div>
    <b-row no-gutters>
      <b-col cols="8">
        <div class="text-regular" v-html="$t(translationPath + 'description_'+accountType)"></div>
      </b-col>
    </b-row>

    <b-table
      class="spirecta-simple-table overview-account-plan-table"
      show-empty
      hover
      responsive
      striped
      :items="items"
      :fields="columns"
      :filter="filter"
      :filter-included-fields="['title']"
      :tbody-tr-class="rowClass"
      stacked="md"
      :emptyText="$t('common.no_data')"
      :emptyFilteredText ="$t('common.no_filter_result')"
      :busy="busy"
    >
      <template v-slot:table-busy>
        <loader />
      </template>
      <template v-slot:head(actions)>
        <b-link class="btn btn-xs btn-secondary" @click.prevent="onQuickAddAccountClick">
          <i class="flaticon stroke plus" />
          {{ btnAddAccountLabel }}
        </b-link>
      </template>
      <template v-slot:head(default_active_percentage)="">
        <span>{{ $t("common.active_percentage") }} </span>
      </template>
      <template slot="top-row">
        <td colspan="5">
          <div class="d-flex align-items-center">
            <i class="fa fa-search text-secondary"></i>
            <b-form-input
              v-model="filter"
              size="sm"
              :placeholder="$t('common.filter_placeholder')"
            />
          </div>
        </td>
      </template>

      <template v-slot:cell(title)="row">
        <template v-if="row.item.is_account === true">
          <span class="px-2 account_title">&ndash;</span>
          <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view?account_type='+row.item.type" class="account_title" :title="row.item.description">{{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}</b-link>
          <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help" :title="$t(translationPath + 'badge.suspended_help')">{{ $t(translationPath + "badge.suspended") }}</b-badge>
          <b-badge v-if="row.item.is_importable" variant="info" class="ml-2">{{ $t( translationPath +'badge.is_importable') }}</b-badge>
          <b-badge v-if="row.item.is_capital_account" variant="info" class="ml-2">{{ $t( translationPath +'badge.is_capital_account') }}</b-badge>
        </template>
        <template v-else-if="row.item.is_account_group === true">
          <b-link :to="'/reports/performance/account-groups/' + row.item.id" class="account_group_title">{{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}</b-link>
        </template>
        <template v-else-if="row.item.is_part_sum_row === true">
          {{ $t('common.subtotal') }}
        </template>
        <template v-else-if="row.item.is_total_sum_row === true">
          {{ $t('common.total') }}
        </template>
      </template>

      <template v-slot:cell(amount)="row">
        <template v-if="row.item.is_account === true">
          {{ row.value }}
        </template>
        <template v-else-if="row.item.is_part_sum_row === true">
          {{ row.value }}
        </template>
        <template v-else-if="row.item.is_total_sum_row === true">
          {{ row.value }}
        </template>
      </template>

      <template v-slot:cell(default_active_percentage)="item">
        <template v-if="item.item.is_account === true">
          {{ item.value }} %
        </template>
      </template>

      <template v-slot:cell(actions)="row">
        <template v-if="row.item.is_account === true">
          <template v-if="showSetUpdateStarting">
            <!--<b-link v-if="item.item.allow_update || !item.item.date"  class="btn plain-btn text-regular action-button" :to="updateValueBaseLink + item.item.id">
              <i class="flaticon solid text-secondary" :class="item.item.date ? 'refresh' : 'money'"></i>
              <span>{{ $t( 'reports.other.account_plan.' + (item.item.date ? "update_value" : "set_starting_value")) }}</span>
            </b-link>-->
          </template>
          <b-link v-if="row.item.type === 'income' || row.item.type ==='expense'" class="btn plain-btn text-regular action-button" :to="'/budget/account/' + row.item.id">
            <i class="flaticon solid calculator-1 text-secondary"></i> <span>{{ $t("common.budget") }}</span>
          </b-link>
          <b-link class="btn plain-btn text-regular action-button" :to="'/reports/performance/accounts/' + row.item.id + '/view'">
            <i class="flaticon stroke graph-1 text-secondary"></i> <span>{{ $t("common.report") }}</span>
          </b-link>
          <button class="btn plain-btn text-regular action-button" @click="handleDeleteItem(row.item)">
            <i class="flaticon solid trash-3 text-secondary"></i> {{ $t("common.delete") }}
          </button>
          <b-link class="btn plain-btn text-regular action-button" :to="editBaseLink + row.item.type + '/' +row.item.id +'/edit'">
            <i class="flaticon solid edit-3 text-primary"></i>
            <span>{{ $t("common.edit") }}</span>
          </b-link>
        </template>
        <template v-if="row.item.is_account_group === true">
          <b-link class="btn plain-btn text-regular action-button" :to="'/reports/performance/account-groups/' + row.item.id">
            <i class="flaticon stroke graph-1 text-secondary"></i><span>{{ $t("common.report") }}</span>
          </b-link>
          <button class="btn plain-btn text-regular action-button" @click="handleDeleteGroup(row.item)">
            <i class="flaticon solid trash-3 text-secondary"></i> {{ $t("common.delete") }}
          </button>
          <b-link class="btn plain-btn text-regular action-button" :to="editBaseLink + 'groups/' + row.item.id + '/edit'">
            <i class="flaticon solid edit-3 text-primary"></i>
            <span>{{ $t("common.edit") }}</span>
          </b-link>
        </template>
        <template v-else>

        </template>
      </template>

    </b-table>

    <create-account-group-account-modal
      ref="QuickAddAccountModal"
      :is-field-type-shown="false"
      @performed="onQuickModalPerformed"
    />
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import CreateAccountGroupAccountModal from '@/components/modals/CreateAccountGroupAccountModal'

export default {
  name: 'AccountPlanOverviewTable',
  components: { Loader, CreateAccountGroupAccountModal },
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    accountType: {
      type: String,
      default: null
    },
    field: {
      type: Array,
      default: function () {
        return []
      }
    },
    busy: {
      type: Boolean,
      default: false
    },
    rowClass: {
      type: Function,
      default: null
    },
    editBaseLink: {
      type: String,
      default: null
    },
    showSetUpdateStarting: {
      type: Boolean,
      default: false
    },
    updateValueBaseLink: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      items: [],
      filter: '',
      translationPath: 'reports.other.account_plan.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    columns () {
      return this.field
    },
    btnAddAccountLabel () {
      let str = 'reports.other.account_plan.'
      switch (this.accountType) {
        case 'income':
          str += 'new_income_category'
          break
        case 'expense':
          str += 'new_expense_category'
          break
        case 'asset':
          str += 'new_asset'
          break
        case 'liability':
          str += 'new_liability'
          break
      }
      return this.$t(str)
    }
  },
  methods: {
    handleDeleteGroup (item) {
      this.$emit('delete-group', item)
    },
    handleDeleteItem (item) {
      this.$emit('delete-item', item)
    },
    onQuickAddAccountClick () {
      this.$refs.QuickAddAccountModal.show({ type: this.accountType })
    },
    onQuickModalPerformed (data) {
      this.$emit('account-created', data)
    }
  },
  watch: {
    data: {
      handler: function (value) {
        this.items = value.filter(item => item.status !== 'suspended') || []
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';

</style>
